<template>
  <div class="my-upload-container">
    <el-form :model="form" label-width="80px">
      <el-upload
        class="upload-demo"
        drag
        ref="upload"
        accept=".xls,.xlsx"
        :action="uploadUrl()"
        :on-change="handleChange"
        :file-list="fileList"
        :auto-upload="false"
        :on-error="onError"
        :on-success="onSuccess"
        :headers="headers"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          <span>说明：</span>
          <br />
          <span>1.发表时间为空时则立即发表</span>
          <br />
          <span>2.单个帐号两次发帖间隔至少3分钟</span>
          <br />
          <span>
            3.单个帐号发帖频率最高为10篇/小时;50篇/天。演示帐号:5篇/天
          </span>
          <br />
          <br />
          <el-link
            href="https://auto-post.oss-cn-beijing.aliyuncs.com/batchDemo/Twitter%E4%BB%BB%E5%8A%A1%E6%89%B9%E9%87%8F%E4%B8%8A%E4%BC%A0%E6%A8%A1%E7%89%88.zip"
            type="success"
          >
            下载模板
          </el-link>
        </div>
      </el-upload>
      <el-form-item label="任务类型">
        <el-radio-group v-model="form.type">
          <el-radio label="1">发帖</el-radio>
          <el-radio label="2">转贴</el-radio>
          <el-radio label="3">评论</el-radio>
          <el-radio label="4">点赞</el-radio>
          <el-radio label="5">举报</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">批量发布任务</el-button>
      </el-form-item>
    </el-form>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <p style="text-align: center">
        本次共计上传:{{ totalCount }}条，成功：{{ successCount }}条，待确认：{{
          errorCount
        }}条
      </p>
      <div v-if="errorCount > 0" style="text-align: center">
        <span style="color: red">【待确认】</span>
        {{ errorData }}
        <span>任务将在您确认后执行</span>
        <!-- <div v-if="form.type === '1'">
          <p v-for="(item, index) in errorData" :key="index">
            <span style="color: red">【失败】</span>
            content:{{ item.content }}--accountId:{{
              item.accountId
            }}--publishTime:{{ item.publishTime }}--media:{{
              item.media
            }}--tweetUrl:{{ item.tweetUrl }}
          </p>
        </div>
        <div v-if="form.type === '2'">
          <p v-for="(item, index) in errorData" :key="index">
            <span style="color: red">【失败】</span>
            accountId:{{ item.accountId }}--tweetUrl:{{
              item.tweetUrl
            }}--forwardTime:{{ item.forwardTime }}
          </p>
        </div>
        <div v-if="form.type === '3'">
          <p v-for="(item, index) in errorData" :key="index">
            <span style="color: red">【失败】</span>
            accountId:{{ item.accountId }}--content:{{
              item.content
            }}--tweetUrl:{{ item.tweetUrl }}--commentTime:{{ item.commentTime }}
          </p>
        </div>
        <div v-if="form.type === '4'">
          <p v-for="(item, index) in errorData" :key="index">
            <span style="color: red">【失败】</span>
            accountId:{{ item.accountId }}--tweetUrl:{{
              item.tweetUrl
            }}--likeTime:{{ item.likeTime }}
          </p>
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getCookie } from '@/plugins/axios'
export default {
  data() {
    return {
      dialogVisible: false,
      action: '',
      fileList: [],
      form: {
        type: ''
      },
      errorCount: 0,
      successCount: 0,
      totalCount: 0,
      errorData: [],
      headers: {
        'X-CSRFToken': getCookie('csrftoken')
      }
    }
  },
  methods: {
    onSubmit() {
      if (this.form.type === '') {
        this.$message.error('请选择任务类型')
      } else {
        this.uploadFile()
      }
    },
    uploadFile() {
      this.$nextTick(() => {
        this.headers = {
          'X-CSRFToken': getCookie('csrftoken')
        }
        this.$refs.upload.submit()
      })
    },
    uploadUrl() {
      if (this.form.type === '1') {
        return process.env.VUE_APP_BASE_API + '/twitter/post_tasks:batch_upload'
      }
      if (this.form.type === '2') {
        return (
          process.env.VUE_APP_BASE_API + '/twitter/forward_tasks:batch_upload'
        )
      }
      if (this.form.type === '3') {
        return (
          process.env.VUE_APP_BASE_API + '/twitter/comment_tasks:batch_upload'
        )
      }
      if (this.form.type === '4') {
        return process.env.VUE_APP_BASE_API + '/twitter/like_tasks:batch_upload'
      }
      if (this.form.type === '5') {
        return (
          process.env.VUE_APP_BASE_API + '/twitter/report_tasks:batch_upload'
        )
      }
      return ''
    },
    onError(error) {
      const response = JSON.parse(error.message)
      // this.$message.error(res.message)
      const { status, message, data } = response

      // console.log(typeof data)
      if (status === 400) {
        if (data === null || data === undefined) {
          this.$message.error(message)
        } else {
          for (var errorField in data) {
            const errorInfos = data[errorField]
            for (var errorInfoIndex in errorInfos) {
              setTimeout(() => {
                this.$message.error(
                  errorField + ': ' + errorInfos[errorInfoIndex]
                )
              }, 1)
            }
          }
        }
      } else if (status === 401) {
        // this.$alert('登录超时，请重新登录', '提示', {
        //   confirmButtonText: '确定',
        //   callback: this.$router.push('/login')
        // })
        this.$message.warning('登录超时，请重新登录')
        const origin = window.localStorage.getItem('origin')
        if (typeof origin === 'undefined' || origin == null || origin === '') {
          this.$router.push({ path: '/login' })
        } else {
          window.location.href = origin
        }
      } else {
        this.$message.error(message)
      }
    },
    onSuccess(res) {
      const { message } = res
      if (res.code === 201) {
        this.totalCount = res.data.total_count
        this.successCount = res.data.success_count
        this.errorCount = res.data.error_count
        this.errorData = res.data.error_data
        this.dialogVisible = true
        this.$message.success(message)
      } else if (res.code === 401) {
        this.$alert('登录超时，请重新登录', '提示', {
          confirmButtonText: '确定',
          callback: this.$router.push('/login')
        })
      } else {
        this.$message.error(res.message)
      }
      this.$refs.upload.clearFiles()
    },
    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]] // 这一步，是 展示最后一次选择的文件
      }
    }
  }
}
</script>

<style scoped>
.my-upload-container {
  height: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .el-list-enter-active,
/deep/ .el-list-leave-active {
  transition: none;
}

/deep/ .el-list-enter,
/deep/ .el-list-leave-active {
  opacity: 0;
}

/deep/ .el-upload-list {
  height: 40px;
}
</style>
